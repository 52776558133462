<script setup lang="ts">
import { PropType, onMounted, ref, onUnmounted, computed } from 'vue';
import ArrowIcon, { Direction } from '../icons/ArrowIcon.vue';
import SearchIcon from '../icons/SearchIcon.vue';
import Dropdown from './Dropdown.vue';

type Option = {
  label: string;
  value: unknown;
};

const props = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  placeHolder: {
    type: String,
    required: true,
  },
  options: {
    type: Array as PropType<Option[]>,
    required: true,
  },
  useBackground: {
    type: Boolean,
  },
  labelStyles: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const dropdownOpen = ref(false);
const root = ref();
const inputValue = ref<string | null>(props.modelValue ?? null);
const inputRef = ref<HTMLElement | null>(null);
const filterValue = ref<string | null>(null);

const computedOptions = computed(() =>
  props.options.filter((option) =>
    option.label.toLocaleLowerCase().includes(filterValue.value?.toLocaleLowerCase() ?? '')
  )
);

const detectClickOutside = async (event: MouseEvent) => {
  if (!root.value?.contains(event.target)) {
    dropdownOpen.value = false;
  }
};

const handleOnClickOption = (option: Option | null) => {
  inputValue.value = option?.label ?? null;
  emit('update:modelValue', option?.value);
  dropdownOpen.value = false;
};

onMounted(() => {
  if (props.disabled) return;

  document.addEventListener('click', detectClickOutside);

  if (props.modelValue) {
    const option = props.options.find((option) => option.value === props.modelValue);

    if (option) inputValue.value = option.label;
  }
});

const focusInput = () => inputRef.value?.focus();

onUnmounted(() => {
  if (props.disabled) return;

  document.removeEventListener('click', detectClickOutside);
});
</script>

<template>
  <div
    ref="root"
    class="text-white"
  >
    <div
      v-if="props.label"
      class="md:p-0 p-0"
      :class="props.labelStyles"
    >
      {{ props.label }}
    </div>
    <Dropdown
      :on-open="focusInput"
      :disabled="props.disabled"
    >
      <template #button="{ isOpen }">
        <slot>
          <div
            class="rounded-md relative select-none px-4 py-2 cursor-pointer"
            :class="`${useBackground ? 'bg-gray-700' : 'border border-gray-600'}
                     ${props.disabled ? 'text-gray-400' : ''}`"
          >
            <div class="relative mr-10">
              {{ inputValue ?? props.placeHolder }}
            </div>
            <div class="absolute right-4 bottom-0 top-0 flex items-center w-2">
              <ArrowIcon :direction="isOpen ? Direction.Up : Direction.Down" />
            </div>
          </div>
        </slot>
      </template>
      <template #default="{ close }">
        <div class="w-full max-h-[300px] z-[20000]">
          <div class="p-2 border-b-[1px] border-gray-500">
            <div class="px-1 rounded-lg flex items-center">
              <SearchIcon />
              <input
                ref="inputRef"
                v-model="filterValue"
                :disabled="props.disabled"
                :placeholder="$t('common.search')"
                class="bg-transparent w-full h-full px-4 py-2 cursor-pointer outline-none"
              >
            </div>
          </div>
          <div class="overflow-auto scroll-bar-thin max-h-[200px] my-4">
            <div
              v-for="option in computedOptions"
              :key="(option.value as any)"
              class="w-full overflow-hidden truncate py-3 px-4 hover:bg-gray-600 cursor-pointer select-none rounded-md"
              @click="() => {
                close()
                handleOnClickOption(option)
              }"
            >
              {{ option.label }}
            </div>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>
